import Faqs from "components/Faq/Faqs";
import CourseDetails from "components/CourseDetailSection/CourseDetails";
import sec1Img from "assets/images/laptop.png";
import { useNavigate } from "react-router-dom";
import { useData } from 'context/DataContext'
import { useTranslation } from "react-i18next";

// 
const CoursePage = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const { user } = useData();
    // 
    const buyNow = () => {
        if (user?.isAuth) {
            navigate("/dashboard/course", { replace: true });
        } else {
            navigate("/login", { replace: true });
        }
    }
    return (
        <>
            <section className="section_" id="coursePage">
                <div className="cs_container">
                    <div className="cs_row">
                        <div className="left_col">
                            <h3>{t("training_program")}</h3>
                            <p>
                                {t("program_desc")}
                            </p>
                            <button onClick={buyNow} className="cs_btn bg_red">{t("buy_now")}</button>
                            <br />
                        </div>
                        <div className="right_col">
                            <img src={sec1Img} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <CourseDetails />
            <Faqs />
            <section id="disclaimerSec">
                <div className="cs_container">
                    <div className="w-100">
                        <div className="sec_heading">
                            <h2>{t("disclaimer_title")}</h2>
                        </div>
                    </div>
                    <br />
                    <p style={{maxWidth: "880px"}} className="mx_auto">
                        {t("disclaimer_desc")}
                    </p>
                    <br />
                    <p style={{maxWidth: "880px"}} className="mx_auto">
                        {t("disclaimer_desc1")}
                    </p>
                    <br />
                </div>
            </section>

        </>
    )
}

export default CoursePage