import "./faq.css";
import { ArrowUpIcon } from 'utils/CsIcon'
import { useState } from 'react';
import { useTranslation } from "react-i18next";

// faq list
const faqData = [{}, {}, {}, {}];

const Faqs = () => {
    const { t } = useTranslation();
    const [activeFaq, setActiveFaq] = useState(null);
    return (
        <section id="faqs">
            <div className="cs_container">
                <div className="cs_row flex-wrap">
                    <div className="w-100">
                        <div className="sec_heading">
                            <h2>{t("faq_sec_title")}</h2>
                        </div>
                    </div>
                    <div className="w-100">
                        <div className="faqs_container">
                            {faqData.map((data, i) => {
                                return (
                                    <div key={i}
                                        className={`faq-wrapper ${activeFaq === i ? 'active-faq' : ''}`}>
                                        <div className="faq-qs" onClick={() => setActiveFaq(prev => prev === i ? null : i)}>
                                            <h6>{t(`faqs.${i}.qs`)}</h6><span className="faq-arrow"><ArrowUpIcon /></span>
                                        </div>
                                        <div id="fa" className="faqs-answer text-white">
                                            <p>
                                                {t(`faqs.${i}.ans`)}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faqs
