import { useData } from 'context/DataContext'
import webLogo from "assets/images/logo.png";

const UsersManage = () => {
    const { users } = useData();
    return (
        <div>
            <h2>Users: ({users?.data?.length})</h2>
            <br />
            <div className="cs_row justify-content-start  flex-wrap">
            {users?.data?.map((v, i) => (
                !v?.isAdmin ?
                <div className="my-2 users_card paper" key={i}>
                    <div className="toolbarIconBox adminAvatarBox">
                        <img className="adminAvatar" src={v?.photoURL || webLogo} alt={v?.firstName?.slice(0, 1)} />
                    </div>
                    <div className="my-2"></div>
                    <h4><strong>Name: </strong> {v?.firstName}</h4>
                    <p>
                        <strong>Email: </strong>  {v?.email}
                    </p>
                </div>
                : null
            ))}
            </div>
        </div>
    )
}

export default UsersManage