import { useState } from 'react'
import { signInWithEmailAndPassword, auth } from 'config/Firebase';
import { Link, Navigate, useParams } from "react-router-dom";
import { useData } from 'context/DataContext'
import { useNotifyContext } from 'context/Notify'
import Loader from "utils/Loader";
import { useTranslation } from 'react-i18next';

const Login = () => {
    const { t } = useTranslation();
    // data context/DataContext
    const { user } = useData();
    // 
    const { email } = useParams();
    // loader & alert
    const { set_notify, setIsLoading } = useNotifyContext();
    // ===========
    let initial = {
        email: email || "",
        password: "",
    }
    // =============
    const [formData, setFormData] = useState(initial);

    // 
    const validateEmail = (val) => {
        let emRgx = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emRgx.test(val)
    }
    // ===== Login user =============
    const login = (e) => {
        e.preventDefault();
        if (validateEmail(formData?.email)) {
            setIsLoading(true);
            signInWithEmailAndPassword(auth, formData?.email, formData?.password)
                .then((userCredential) => {
                    setIsLoading(false);
                    const user = userCredential.user;
                    set_notify({ msg: `${user?.email} Login successfully!`, open: false, type: "success" })
                })
                .catch((error) => {
                    let msg;
                    switch (error.code) {
                        case 'auth/invalid-email':
                            msg = 'Invalid Email address.'
                            break;
                        case 'auth/user-not-found':
                            msg = 'User is not exist.'
                            break;
                        case 'auth/wrong-password':
                            msg = 'Email or Password is incorrect.'
                            break;
                        default:
                            msg = "Something went wrong!"
                    }
                    setIsLoading(false);
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode)
                    console.log(errorMessage)
                    set_notify({ msg: msg, open: true, type: "error" })
                });
        } else {
            set_notify({ msg: `Invalid Email address`, open: true, type: "info" });
        }

    };

    if (user?.isAuth) {
        return <Navigate to="/dashboard/profile" />
    } else if (user.isLoading) {
        return (
            <main className="page_wrapper">
                <div className="cs_container text_center">
                    <Loader type="imgloader" />
                </div>
            </main>
        )
    }

    return (
        <div className="page_wrapper login_page cs_row">
            <div className="cs_container text_center">
                <form className="cs_form login_form" onSubmit={login}>
                    <div className="form_heading">
                        <h3>{t("login_to_your_account")}</h3>
                    </div>
                    <div className="cs_row align-items-center  py-2">
                        <div className="cs_row form_label_wrapper align-items-center">
                            <span className="cs_icons">&#9993;</span>
                            <label htmlFor="email">{t("email")}:</label>
                        </div>
                        <input onChange={(e) => setFormData({ ...formData, email: e.target.value })} type="email" placeholder={t("email_placeholder")} name="email" id="email" value={formData.email} />
                    </div>
                    <div className="cs_row py-2">
                        <div className="cs_row form_label_wrapper align-items-center">
                            <span className="cs_icons">&#8792;</span>
                            <label htmlFor="password">{t("password")}:</label>
                        </div>
                        <input onChange={(e) => setFormData({ ...formData, password: e.target.value })} type="password" placeholder={t("password_placeholder")} name="password" id="password" value={formData.password} />
                    </div>
                    <div className="cs_row py-2 justify-content-center align-items-center">
                        <button type="submit" className="cs_btn">{t("nav.login")}</button>
                    </div>
                    <div className="cs_row text_center my-2">
                        <p>{t("not_have_acc")}? <Link className="a-primary" to="/signup"> {t("signup")} </Link></p>
                    </div>
                    <div className="cs_row justify-content-end my-2">
                        <Link className="a-primary" to={`/forgot`}>{t("forgot_pass")}</Link>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login