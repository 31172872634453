import { useData } from 'context/DataContext'
import { useNotifyContext } from 'context/Notify'
import CourseTable from "../Admin/CourseManage/CourseTable"
import { db, ref, update } from 'config/Firebase';
import { runTransaction } from "firebase/database";
import { useTranslation } from 'react-i18next';

const CoursePanel = () => {
  const { t } = useTranslation();
  const { user, data, setUser, getCourseData } = useData();
  const { set_notify, setIsLoading } = useNotifyContext();

  // course payment will be done here
  const buyNow = (polish) => {
    setIsLoading(true);
    setTimeout(() => {
      update(ref(db, 'users/' + user?.data?.id), {
        paymentCompleted: true,
        courseLang: polish ? "polish" : "english"
      })
        .then(() => {
          getCourseData({
            ...user.data,
            paymentCompleted: true,
            courseLang: polish ? "polish" : "english"
          }
          )
          setIsLoading(false);
          set_notify({ open: true, msg: "Congrats! You have successfully purchased.", type: "success" });
          setUser({
            ...user,
            data: {
              ...user.data,
              paymentCompleted: true,
              courseLang: polish ? "polish" : "english"
            }
          })
        }).catch((err) => {
          console.log(err);
          set_notify({
            open: true,
            msg: "Something went wrong. Please try again.",
            type: "error",
          });
          setIsLoading(false);
        });
    }, [1500])
  }

  const handleWatched = (blogId, setActiveTopicData) => {
    const postRef = ref(db, `/course/${blogId}`);
    let uid = user?.data?.id;

    runTransaction(postRef, (post) => {
      if (post) {
        if (post.isCompleted && post.isCompleted[uid]) {
          post.viewsCompleted--;
          post.isCompleted[uid] = null;
        } else {
          post.viewsCompleted++;
          if (!post.isCompleted) {
            post.isCompleted = {};
          }
          post.isCompleted[uid] = true;
        }
      }
      setActiveTopicData({
        isSelected: true,
        ...post,
      })
      return post;
    });
  };

  // 
  return (
    <div>
      <h2>{t("my_course")}:</h2>
      {user?.data?.paymentCompleted ?
        <>
          <CourseTable data={data?.data || []} isAdmin={user?.isAdmin} handleWatched={handleWatched} />
        </> :
        <div className="text_center">
          <h2>{t("no_course_purchased")}</h2><br />
          <h2>{t("buy_now")}!</h2><br />
          <div className="cs_row justify-content-center">
            <button onClick={() => buyNow("")} className="cs_btn">English medium</button>
            <div>
              &nbsp;
            </div>
            <button onClick={() => buyNow("polish")} className="cs_btn">Polish medium</button>
          </div>
        </div>
      }
    </div>
  );
}


export default CoursePanel;
