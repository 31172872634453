import { createContext, useContext, useState, useEffect } from 'react';
import Alert from "utils/Alert";
import Loader from "utils/Loader";

const NotifyContext = createContext({});

const NotifyContextProvider = ({ children }) => {
  const [notify, setNotify] = useState({ open: false, type: "", msg: "" });
  const [isLoading, setIsLoading] = useState(false);
  const set_notify = (newData) => {
    setNotify(newData);
  };

  useEffect(() => {
    const checkConnection = () => {
      window.addEventListener("online", () => {
        // Set hasNetwork to online when they change to online.
        set_notify({ type: "success", msg: "Your internet connection was restored", open: true, });
      });
      window.addEventListener("offline", () => {
        // Set hasNetwork to offline when they change to offline.
        set_notify({ btnText: "Refresh", onClick: () => window.location.reload(), btn: true, type: "error", msg: `You are currently offline.`, open: true, });

      });
    }
    return checkConnection();
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <NotifyContext.Provider value={{ notify, set_notify, isLoading, setIsLoading }}>
      {children}
      <Alert data={notify} setAlert={setNotify} />
      {isLoading && <Loader type="imgloader_fixed" />}
    </NotifyContext.Provider>
  );
};

export const useNotifyContext = () => useContext(NotifyContext);

export default NotifyContextProvider;