// Custom Modal reusable
const Modal = ({ open, setClose, children }) => {
    return (
        open ?
            <div className="cs_modal_wrapper">
                {children}
                <div onClick={() => setClose(false)} className="cs_modal_backdrop"></div>
            </div>
            : null
    );
};

export default Modal;