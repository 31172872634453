import { useState, useEffect } from "react";
import { useData } from "context/DataContext";
import { AiFillEdit } from "react-icons/ai";
import { IoMdDoneAll } from "react-icons/io";
import posterImg from "assets/images/logo.png";
import { BsTrash } from "react-icons/bs";
import Modal from "utils/Modal";
import { remove } from "firebase/database";
import {
  db,
  set,
  update,
  ref,
  child,
  push,
  storage,
  refForStorage,
  uploadBytesResumable,
  getDownloadURL,
} from "config/Firebase";
import { useNotifyContext } from "context/Notify";
import Loader from "utils/Loader";
import { useTranslation } from "react-i18next";

const CourseTable = ({
  isAdmin,
  setViewForm,
  setUpdateMode,
  setFormData,
  formData,
  handleWatched,
  data = []
}) => {
  const { t } = useTranslation();
  const { user, data: courseData } = useData();
  const [activeTopic, setActiveTopic] = useState(null);
  const { set_notify, setIsLoading } = useNotifyContext();
  const [activeTopicData, setActiveTopicData] = useState({ isSelected: false });
  //
  const [deleteModal, setDeleteModal] = useState({ open: false, id: "" });
  //
  useEffect(() => {
    let find = data?.find((item) => item?.id === activeTopic) || {};
    if (Object.values(find).length > 0) {
      setActiveTopicData({
        ...find,
        isSelected: true,
      });
    } else {
      setActiveTopicData({
        isSelected: false,
      });
    }
    if (activeTopicData.isSelected) {
      setTimeout(() => {
        document.querySelector(".course_video_wrapper")?.scrollIntoView(true);
      }, 100);
    }
  }, [activeTopic]);

  let calculateWatchedRatio = () => {
    let completedItems =
      data?.filter(
        (v) => v?.isCompleted && v?.isCompleted[user?.data?.id] === true
      ) || [];
    return (completedItems?.length * 100) / data?.length;
  };

  const deleteLesson = () => {
    let re = child(ref(db), `course/${deleteModal?.id}`);
    setIsLoading(true);
    remove(re)
      .then(() => {
        set_notify({
          open: true,
          msg: "Lesson deleted successfully.",
          type: "success",
        });
        setIsLoading(false);
        setDeleteModal({ open: false, id: "" });
      })
      .catch((err) => {
        console.log(err);
        setDeleteModal({ open: false, id: "" });
        set_notify({ open: true, msg: "Something went wrong.", type: "error" });
        setIsLoading(false);
      });
  };

  let countLength = () => {
    let filter = isAdmin ? data : data?.filter((v) => v?.publish === "true" || v?.publish === true);
    return parseInt(filter[filter?.length - 1]?.chapterNo);
  };

  const newDataArray = (length) => {
    let arr = [];
    for (let i = 0; i < length; i++) {
      // 3rd
      let newdata = data?.filter((el) => parseInt(el?.chapterNo) === i + 1) || [];
      if (newdata.length > 1) {
        arr.push(newdata);
      } else {
        arr.push(newdata[0] || {});
      }

      // ========= 2nd
      //  data.data?.filter((el) => parseInt(el?.chapterNo) === i+1)?.map((v)=>
      //  arr.push(v || {})
      //  )
      // ======== 1st
      // arr.push(
      //   data.data?.find((el) => parseInt(el?.chapterNo) === i + 1) || {}
      // );
    }
    return arr;
  };

  return (
    <section>
      <div className="cs_container">
        {courseData?.loaded ?
          newDataArray(countLength())?.length > 0 ?
            <div className="cs_row course_main_row">
              <div className="course_left">
                <div className="all_course_list">
                  <header>{t("course_chapters")} ({data?.length})</header>
                  <div className="course_topics_body">
                    {newDataArray(countLength()).map((v, i) =>
                      v?.id || v?.length > 0 ? (
                        v?.length > 0 ?
                          v.map((a, ai) => (
                            <div
                              onClick={() => setActiveTopic(a?.id)}
                              className={`course_topics_title_box 
                        ${a?.publish && isAdmin ? 'publish' : ""}
                        ${activeTopicData?.id === a?.id ? "selected_video" : ""
                                }`}
                              key={ai}
                            >
                              {/* <h3>Rozdział: {v?.chapterNo} </h3> */}
                              {/* <h3>{t("lesson")}: {i + 1 < 9 ? "0" + (i + 1) : i + 1} </h3> */}
                              <h3>{a?.title?.slice(0, 100)}  </h3>
                              <p>{t("lesson")}: {i + 1 < 9 ? "0" + (i + 1) : i + 1}</p>
                              {/* <p>{a?.title?.slice(0, 100)}</p> */}
                              {isAdmin && (
                                <button
                                  onClick={() => {
                                    setViewForm();
                                    setFormData(a);
                                    setUpdateMode({ ...a, enable: true });
                                  }}
                                  className="cs_icon_btn"
                                >
                                  <AiFillEdit />
                                </button>
                              )}
                              {isAdmin && (
                                <button
                                  onClick={() => {
                                    setDeleteModal({ open: true, id: a?.id });
                                  }}
                                  className="cs_icon_btn course_del_btn"
                                >
                                  <BsTrash />
                                </button>
                              )}
                              {a?.isCompleted &&
                                a?.isCompleted[user?.data?.id] === true && (
                                  <button disabled className="cs_icon_btn completed">
                                    <IoMdDoneAll />
                                  </button>
                                )}
                            </div>
                          ))
                          :
                          <div
                            onClick={() => setActiveTopic(v?.id)}
                            className={`course_topics_title_box
                      ${v?.publish && isAdmin ? 'publish' : ""}
                        ${activeTopicData?.id === v?.id ? "selected_video" : ""
                              }`}
                            key={i}
                          >
                            {/* <h3>Rozdział: {v?.chapterNo} </h3> */}
                            {/* <h3>Rozdział: {i + 1 < 9 ? "0" + (i + 1) : i + 1} </h3>
                      <p>{v?.title?.slice(0, 100)}</p> */}
                            <h3>{v?.title?.slice(0, 100)}  </h3>
                            <p>{t("lesson")}: {i + 1 < 9 ? "0" + (i + 1) : i + 1}</p>
                            {isAdmin && (
                              <button
                                onClick={() => {
                                  setViewForm();
                                  setFormData(v);
                                  setUpdateMode({ ...v, enable: true });
                                }}
                                className="cs_icon_btn"
                              >
                                <AiFillEdit />
                              </button>
                            )}
                            {isAdmin && (
                              <button
                                onClick={() => {
                                  setDeleteModal({ open: true, id: v?.id });
                                }}
                                className="cs_icon_btn course_del_btn"
                              >
                                <BsTrash />
                              </button>
                            )}
                            {v?.isCompleted &&
                              v?.isCompleted[user?.data?.id] === true && (
                                <button disabled className="cs_icon_btn completed">
                                  <IoMdDoneAll />
                                </button>
                              )}
                          </div>
                      ) : (
                        <div
                          // disabled
                          className={`course_topics_title_box disabled`}
                          key={i}
                        >
                          {/* <h3>Rozdział: {i + 1 < 9 ? "0" + (i + 1) : i + 1} </h3>
                      <p></p> */}
                          <h3>Coming Soon</h3>
                          <p>{t("lesson")}: {i + 1 < 9 ? "0" + (i + 1) : i + 1}</p>
                          {isAdmin && (
                            <button
                              onClick={() => {
                                setViewForm();
                                setFormData({
                                  ...formData,
                                  chapterNo: i + 1
                                });
                              }}
                              className="cs_icon_btn"
                            >
                              <AiFillEdit />
                            </button>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
                {!isAdmin && activeTopicData?.isSelected ? (
                  activeTopicData?.isCompleted &&
                    activeTopicData?.isCompleted[user?.data?.id] === true ? (
                    <button
                      onClick={() =>
                        handleWatched(activeTopicData?.id, setActiveTopicData)
                      }
                      className="cs_btn"
                    >
                      {t("unmark")}
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        handleWatched(activeTopicData?.id, setActiveTopicData)
                      }
                      className="cs_btn"
                    >
                      {t("mark")}
                    </button>
                  )
                ) : null}
                {!isAdmin && data?.length > 0 && (
                  <div className="watched_percentage_wrapper">
                    <div>
                      <h3>{t("overall_progress")}</h3>
                    </div>
                    <div className="cs_progress_wrapper">
                      <div
                        className="cs_progress"
                        style={{ width: calculateWatchedRatio() + "%" }}
                      ></div>
                    </div>
                    <div className="cs_row justify-content-between align-items-center">
                      <span>{Math.floor(calculateWatchedRatio())}%</span>
                      <span>100%</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="course_right">
                {/* {activeTopicData?.isSelected ? */}
                <>
                  <div className="course_video_wrapper">
                    <video
                      id="video"
                      autoPlay
                      controls
                      poster={posterImg}
                      src={activeTopicData?.lecture}
                    ></video>
                  </div>
                  <div className="course_description">
                    <h1>{activeTopicData?.title}</h1>
                    <p>{activeTopicData?.description}</p>
                  </div>
                </>
                {/* :
                            null
                        } */}
              </div>
            </div>
            :
            <div className="cs_row course_main_row justify-content-center">
              <h3 className="text_center">{t("no_lectures")}</h3>
            </div>
          :
          <>
            <br />
            <Loader type="imgloader" />
            <br />
          </>
        }
      </div>

      {/*  */}
      <Modal
        open={deleteModal?.open}
        setClose={() => {
          setDeleteModal({ open: false, id: "" });
        }}
      >
        <div className="cs_modal ask_modal">
          <div className="cs_modal_body">
            <h2>Alert!</h2>
            <p>Are you sure you want to delete this lesson?</p>
          </div>
          <div className="cs_modal_footer cs_row justify-content-between align-items-center">
            <button className="cs_btn" onClick={deleteLesson}>
              Delete
            </button>
            <button
              className="cs_btn"
              onClick={() => setDeleteModal({ open: false, id: "" })}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default CourseTable;
