import introImg from "assets/images/intro.png";
import sec_2 from "assets/images/sec_2.jpeg";
import sec_4 from "assets/images/sec_4.jpg";
import { useNavigate } from "react-router-dom";
import { useData } from "context/DataContext";
import { useTranslation } from "react-i18next";

const Home = () => {
  let navigate = useNavigate();
  const { user } = useData();
  const { t } = useTranslation();

  //
  const buyNow = () => {
    if (user?.isAuth) {
      navigate("/dashboard/course", { replace: true });
    } else {
      navigate("/login", { replace: true });
    }
  };

  return (
    <main className="page_wrapper">

      <section id="hero">
        <div className="cs_container">
          <div className="cs_row flex-wrap justify-content-center align-items-center">
            <div className="hero_col_left">
              <h1>{t("welcome_headline")}</h1>
              <p>
                {t("welcome_para")}
              </p>
              <button onClick={buyNow} className="cs_btn">
                Singular Trader Academy
              </button>
            </div>
            <div className="hero_col_right">
              <img src={introImg} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="section_" id="">
        <div className="cs_container">
          <div className="cs_row">
            <div className="right_col">
              <img src={sec_2} alt="" />
            </div>
            <div className="left_col">
              <h3>{t("sec2_title")}</h3>
              <p>
                {t("sec2_desc")}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section_" id="about">
        <div className="cs_container">
          <div className="w-100 text_center px-2">
            <div className="sec_heading">
              <h2>{t("nav.about")}</h2>
            </div>
            <p style={{ maxWidth: "850px" }} className="mx_auto">
              {t("about_desc1")}
              <br />
              {t("about_desc2")}
            </p>
          </div>
        </div>
      </section>

      <section className="section_" id="">
        <div className="cs_container">
          <div className="cs_row">
            <div className="left_col">
              <h3>{t("sec4_title")}</h3>
              <p>• {t("sec4_features.0")}</p>
              <p>• {t("sec4_features.1")}</p>
              <p>• {t("sec4_features.2")}</p>
              <p>• {t("sec4_features.3")}</p>
              <p>• {t("sec4_features.4")}</p>
              <p>• {t("sec4_features.5")}</p>
              <p>• {t("sec4_features.6")}</p>
            </div>
            <div className="right_col">
              <img src={sec_4} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section id="banner">
        <div className="cs_container">
          <div className="cs_row">
            <h2>Singular Trader Academy.</h2>
            <button onClick={buyNow} className="cs_btn">
              {t("join_btn")}
            </button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
