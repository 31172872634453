
import anatomy from "assets/images/anatomy.png"
import info from "assets/images/info.png"
import advance from "assets/images/advance.jpg"
import str from "assets/images/str.jpg"
import { useNavigate } from "react-router-dom";
import { useData } from 'context/DataContext'
import { useTranslation } from "react-i18next";

const CourseDetails = () => {
    const { t } = useTranslation();
    // 
    let data = [
        { img: anatomy },
        { img: info },
        { img: advance },
        { img: str }
    ];
    let navigate = useNavigate();
    const { user } = useData();
    // 
    const buyNow = () => {
        if (user?.isAuth) {
            navigate("/dashboard/course", { replace: true });
        } else {
            navigate("/login", { replace: true });
        }
    }
    return (
        <section id="courseDetails">
            <div className="cs_container">
                <div className="w-100">
                    <div className="sec_heading">
                        <h2>{t("chapter_sec_title")}</h2>
                    </div>
                </div>
                <div className="cs_row flex-wrap">
                    {data.map((item, index) => (
                        <div className="lesson_card" key={index}>
                            <div className="thumbnail">
                                <img src={item.img} alt={item.title} />
                            </div>
                            <div className="about_lesson_card_right">
                                <h4>{t(`chapters.${index}.title`)}</h4>
                                <p>
                                    {t(`chapters.${index}.desc`)}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
                <br />
                <div className="text_center">
                    <button onClick={buyNow} className="cs_btn mx_auto bg_red">{t("buy_now")}</button>
                </div>
                <br />
            </div>
        </section>
    )
}

export default CourseDetails
