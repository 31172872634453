import "./footer.css";
import { useNavigate, useLocation } from "react-router-dom";
import webLogo from "assets/images/logo_main.png";
import { useTranslation } from "react-i18next";

// 
const Footer = () => {
  const { t } = useTranslation();
  let location = useLocation();
  const navigate = useNavigate();

  const scrollTo_Section = (sec_id) => {
    let element = document.getElementById(sec_id);
    const yOffset = -77;
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      } else {
      let path = location?.pathname === "/" ? "/course" : "/";
      navigate(path, { replace: true });
      setTimeout(() => {
        let new_element = document.getElementById(sec_id);
        if (new_element) {
          const y = new_element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 150);
    }
  };

  return (
    <>
      <footer className="web_footer">
        <div className="cs_container">
          <div className="cs_row footer_row">
            <div className="footer_col">
              <div className="footer_logo">
                <img src={webLogo} alt="" />
                <p className="my-md-4">
                  <br />
                  {t("nav.contact")}: {" "}
                  <a href="mailto:admin@singulartrader.com" className="text-white" target="_blank" rel="noopener noreferrer">
                    admin@singulartrader.com
                  </a>
                </p>
              </div>
              {/* <div className="footer_social_wrapper cs_row">
                <a href="/" target="_blank" rel="noopener noreferrer" className="cs_ft_social_btn  cs_row fb_twit"><BsInstagram /></a>
                <a href="/" target="_blank" rel="noopener noreferrer" className="cs_ft_social_btn  cs_row fb_twit"><BsFacebook /></a>
                <a href="/" target="_blank" rel="noopener noreferrer" className="cs_ft_social_btn cs_row  fb_twit"><BsTwitter /></a>
                <a href="/" target="_blank" rel="noopener noreferrer" className="cs_ft_social_btn cs_row text-black"><BsLinkedin /></a>
              </div> */}
            </div>
            <div className="footer_col ft_col_2">
              <h3 className="footer_col_head">
                {t("useful_links")}
              </h3>
              <ul>
                <li><a onClick={() => scrollTo_Section('hero')}>{t("nav.home")}</a></li>
                <li><a onClick={() => scrollTo_Section('courseDetails')}>{t("nav.course")}</a></li>
                <li><a onClick={() => scrollTo_Section('about')}>{t("nav.about")}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      {/*  */}
      <div className="copyright_area">
        <div className="cs_container cs_row">
          <div className="copyright_text">
            <span>Copyright © <span id="year">{new Date().getFullYear()}</span> All Right Reserved <span className="footer_name"> Singular Trader Academy.</span>
            </span>
            <span style={{display: "block"}}>
             {" "} Created by  <a className="a-white" href="https:www.smashcode.dev" target="_blank" rel="noreferrer"> Smash Code</a>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
