import { useState } from 'react'
import { sendPasswordResetEmail, auth } from 'config/Firebase';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useData } from 'context/DataContext'
import { useNotifyContext } from 'context/Notify'
import { useTranslation } from 'react-i18next';

// 
const Forgot = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // data context/DataContext
    const { user } = useData();
    // loader & alert
    const { set_notify, setIsLoading } = useNotifyContext();
    // ===========
    let initial = { email: "", }
    // =============
    const [formData, setFormData] = useState(initial);

    // 
    const validateEmail = (val) => {
        let emRgx = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emRgx.test(val)
    }
    // ===== Forgot user =============
    const sendForgot = (e) => {
        e.preventDefault();
        if(validateEmail(formData.email)){
            setIsLoading(true);
            sendPasswordResetEmail(auth, formData?.email)
                .then(() => {
                    setIsLoading(false);
                    set_notify({ msg: `Reset Email has been sent to your email ${formData?.email}`, open: true, type: "success" });
                    navigate("/login/" + formData?.email, { replace: true });
                })
                .catch((error) => {
                    let msg;
                    switch (error.code) {
                        case 'auth/invalid-email':
                            msg = 'Invalid Email address.'
                            break;
                        case 'auth/user-not-found':
                            msg = 'User is not exist.'
                            break;
                        case 'auth/missing-email':
                            msg = 'Email address is missing.'
                            break;
                        default:
                            msg = "Something went wrong!"
                    }
                    setIsLoading(false);
                    const errorMessage = error.message;
                    console.log(errorMessage)
                    set_notify({ msg: msg, open: true, type: "error" })
                });
        }else{
            set_notify({ msg: `Invalid Email address`, open: true, type: "info" });
        }

    };

    if (user?.isAuth) {
        return <Navigate to="/dashboard/profile" />
    }

    return (
        <div className="page_wrapper login_page cs_row">
            <div className="cs_container">
                <form className="cs_form login_form" onSubmit={sendForgot}>
                    <Link to="/login" className="cs_btn sm outlined"><span className="cs_icons">&#8656;</span> {t("back")}</Link>
                    <div className="form_heading">
                        <h3>{t("forgot_pass")}</h3>
                    </div>
                    <div className="w-100  my-2">
                        <div className="w-100">
                            <label htmlFor="email">{t("email")}:</label>
                        </div>
                        <input
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            type="email"
                            placeholder={t("email_placeholder")}
                            name="email"
                            id="email"
                            value={formData.email} />
                    </div>
                    <div className="cs_row py-2 justify-content-center align-items-center">
                        <button type="submit" className="cs_btn">{t("send")}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Forgot