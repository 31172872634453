// css
import "assets/css/style.css"
// pages & components
import Home from "pages/Home";
import CoursePage from "pages/Course";
import Dashboard from "pages/Dashboard";
import Header from "layout/Header/Header";
import Footer from "layout/Footer/Footer";
import SignUp from "components/signup";
import Login from "components/login";
import Forgot from "components/Forgot";
// react router
import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect} from "react";
import LanguageSelector from "components/languageSelector";

function PageScrollWhenChange() {
  const { pathname } = useLocation();
  // scroll top of the page when route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function AppRoutes() {
  return (
    <>
      {!IsDashboard() && <> <LanguageSelector /> <Header /> </>}
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/home" exact element={<Home />} />
        <Route path="/en" exact element={<Home />} />
        <Route path="/pol" exact element={<Home />} />
        <Route path="/course" exact element={<CoursePage />} />
        <Route path="/dashboard" exact element={<Dashboard />}/>
        <Route path="/dashboard/:tabName" exact element={<Dashboard />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/login/:email" exact element={<Login />} />
        <Route path="/signup" exact element={<SignUp />} />
        <Route path="/forgot" exact element={<Forgot />} />
        <Route path="/forgot/:email" exact element={<Forgot />} />
      </Routes>
      {!IsDashboard() && <Footer />}
      <PageScrollWhenChange/>
    </>
  );
}

const IsDashboard = () => {
  const location = useLocation();
  return location?.pathname.includes('dashboard')
}



export default AppRoutes;
