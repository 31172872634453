import { useEffect } from "react";

// custom alert reusable
const Alert = ({ data,setAlert }) => {
    let colors = {error:'red',success:'green',warning:'yellow',info: "info"};
    useEffect(()=> {
        if(data?.open){
            setTimeout(()=>{
                setAlert({open:false, msg:"", type: ""})
            },3000)
        }
    },[data]);
    return (
        data?.open ? 
        <div className={`cs_alert_wrapper cs_row flex-wrap justify-content-center align-items-center ${colors[data?.type]}`}>
            <p className="mx-2">{data?.msg}&nbsp;</p>
            <button className="cs_icon_btn sm">&times;</button>
        </div>
         : null
    )
};

export default Alert;