import mblogo from "assets/images/logo.png";
import logo from "assets/images/logo_main.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { useData } from "context/DataContext";
import { useEffect, useRef } from "react";
import webLogo from "assets/images/logo.png";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const Header = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const { hash } = useLocation();
  const { t } = useTranslation()
  // data context/DataContext
  const { user } = useData();
  const [menuOpen, setMenuOpen] = useState(false);

  const scrollTo_Section = (sec_id, thispageonly) => {
    let element = document.getElementById(sec_id);
    const yOffset = -77;
    if (element) {
      // setActiveMenu(sec_id);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    } else {
      if (thispageonly) return
      let path = location?.pathname === "/" ? "/course" : "/";
      navigate(path, { replace: true });
      setTimeout(() => {
        let element = document.getElementById(sec_id);
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }, 230);
    }
  };

  const deskHeader = useRef();

  useEffect(() => {
    //
    let checkScroll = () => {
      //
      var pageOffset =
        document.documentElement.scrollTop || document.body.scrollTop;
      let btn = document.getElementById("scrollToTop");
      //
      if (btn) {
        if (pageOffset > 450) {
          btn.classList.add("show-top");
        } else {
          btn.classList.remove("show-top");
        }
      }
    };

    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  useEffect(() => {
    if (hash) {
      scrollTo_Section(hash?.slice(1), "thispageonly")
    }
  }, [hash])


  return (
    <header ref={deskHeader} className="web_header">
      <div className="cs_container">
        <div className="cs_row">
          <div className="header_logo">
            <Link onClick={() => { setMenuOpen(false) }} to="/">
              <img src={logo} alt="" />
              <img className="mobile_logo" src={mblogo} alt="" />
            </Link>
          </div>
          <div className="header_right">
            <ul className={menuOpen ? 'opened' : ""}>
              <li>
              <span
                  className="cs_navlinks"
                  onClick={() => { scrollTo_Section("hero"); setMenuOpen(false) }}
                >
                  {t("nav.home")}
                </span>
                {/* <Link onClick={() => { setMenuOpen(false) }} to="/" className="cs_navlinks">
                  {t("nav.home")}
                </Link> */}
              </li>
              <li>
                <span
                  className="cs_navlinks"
                  onClick={() => { scrollTo_Section("about"); setMenuOpen(false) }}
                >
                  {t("nav.about")}
                </span>
              </li>
              <li>
                <span
                  className="cs_navlinks"
                  onClick={() => { scrollTo_Section("coursePage"); setMenuOpen(false) }}
                >
                  {t("nav.course")}
                </span>
              </li>
              <li>
              <span
                  className="cs_navlinks"
                  onClick={() => { scrollTo_Section("faqs"); setMenuOpen(false) }}
                >
                  {t("nav.faq")}
                </span>
              </li>
              <li>
                <span
                  className="cs_navlinks"
                  onClick={() => { scrollTo_Section("disclaimerSec"); setMenuOpen(false) }}
                >
                  {t("nav.disclaimer")}
                </span>
              </li>
            </ul>
            {user?.isAuth ? (
              <Link onClick={() => { setMenuOpen(false) }} to="/dashboard/profile">
                <div className="toolbarIconBox adminAvatarBox">
                  <img
                    className="adminAvatar"
                    src={user?.data?.photoURL || webLogo}
                    alt={user?.data?.firstName?.slice(0, 1)}
                  />
                </div>
              </Link>
            ) : (
              <Link onClick={() => { setMenuOpen(false) }} className="cs_btn" to="/login">
                {t("nav.login")}
              </Link>
            )}
            <div onClick={() => { setMenuOpen(!menuOpen) }} className="header_menu_btn">
              {menuOpen ? <AiOutlineCloseCircle /> : <BiMenuAltRight />}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
