import { useState } from 'react'
import { createUserWithEmailAndPassword, auth, db, set, ref } from 'config/Firebase';
import { Link, Navigate } from "react-router-dom";
import { useData } from 'context/DataContext'
import { useNotifyContext } from 'context/Notify'
import Loader from "utils/Loader";
import { useTranslation } from 'react-i18next';

const SignUp = () => {
    const { t } = useTranslation();
    // data context/DataContext
    const { user, setUser, send_Verification_Email } = useData();
    // loader & alert
    const { set_notify, setIsLoading } = useNotifyContext();
    // ===========
    let initial = {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
    }
    // =============
    const [formData, setFormData] = useState(initial);
    // 
    const validateEmail = (val) => {
        let emRgx = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emRgx.test(val)
    }
    // ===== signUpUser user =============
    const signUpUser = (e) => {
        e.preventDefault();
        if (validateEmail(formData?.email)) {
            setIsLoading(true);
            createUserWithEmailAndPassword(auth, formData?.email, formData?.password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    let obj = {
                        firstName: formData?.firstName || user?.displayName || "",
                        lastName: formData?.lastName || "",
                        email: user?.email,
                        id: user?.uid,
                        createdAt: user.metadata?.createdAt || "",
                        photoURL: user?.photoURL || "",
                    }
                    set(ref(db, 'users/' + user?.uid), obj).then(() => {
                        // send_Verification_Email()
                        setUser({ isAuth: true, isLoading: false, data: obj })
                        setIsLoading(false);
                        set_notify({ open: true, msg: "Signup successfully.", type: "success" });
                    }).catch((err) => {
                        console.log(err);
                        setIsLoading(false);
                        set_notify({ open: true, msg: "Something went wrong!", type: "error" });
                    });
                })
                .catch((error) => {
                    let msg;
                    switch (error.code) {
                        case 'auth/invalid-email':
                            msg = 'Invalid Email address.'
                            break;
                        case 'auth/user-not-found':
                            msg = 'User is not exist.'
                            break;
                        case 'auth/email-already-in-use':
                            msg = 'Email is already in use.'
                            break;
                        case 'auth/weak-password':
                            msg = 'Password is too weak.Should be at least 6 characters '
                            break;
                        default:
                            msg = "Something went wrong!"
                    }
                    setIsLoading(false);
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode)
                    console.log(errorMessage)
                    set_notify({ msg: msg, open: true, type: "error" })
                });
        } else {
            set_notify({ msg: `Invalid Email address`, open: true, type: "info" });
        }

    };

    if (user?.isAuth) {
        return <Navigate to="/dashboard/profile" />
    }else if (user.isLoading) {
        return (
            <main className="page_wrapper">
                <div className="cs_container text_center">
                    <Loader type="imgloader" />
                </div>
            </main>
        )
    }

    return (
        <div className="page_wrapper login_page cs_row">
            <div className="cs_container">
                <form className="cs_form login_form" onSubmit={signUpUser}>
                    {/*  */}
                    <div className="form_heading">
                        <h3>{t("create_an_acc")}</h3>
                    </div>
                    {/*  */}
                    <div className="w-100 my-2">
                        <div className="w-100">
                            <label htmlFor="firstName"> {t("f_name")}:</label>
                        </div>
                        <input
                            type="text"
                            placeholder={t("f_name_placeholder")}
                            name="firstName"
                            id="firstName"
                            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                            value={formData.firstName}
                        />
                    </div>
                    {/*  */}
                    <div className="w-100 my-2">
                        <div className="w-100">
                            <label htmlFor="lastName"> {t("l_name")}:</label>
                        </div>
                        <input
                            type="text"
                            placeholder={t("l_name_placeholder")}
                            name="lastName"
                            id="lastName"
                            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                            value={formData.lastName}
                        />
                    </div>
                    {/*  */}
                    <div className="w-100 my-2">
                        <div className="w-100">
                            <label htmlFor="email">{t("email")}: </label>
                        </div>
                        <input onChange={(e) => setFormData({ ...formData, email: e.target.value })} type="email" placeholder={t("email_placeholder")} name="email" id="email" value={formData.email} />
                    </div>
                    {/*  */}
                    <div className="w-100 my-2">
                        <div className="w-100">
                            <label htmlFor="password">{t("password")}:</label>
                        </div>
                        <input onChange={(e) => setFormData({ ...formData, password: e.target.value })} type="password" placeholder={t("password_placeholder")} name="password" id="password" value={formData.password} />
                    </div>
                    {/*  */}
                    <div className="cs_row py-2 justify-content-center align-items-center">
                        <button type="submit" className="cs_btn">{t("signup")}</button>
                    </div>
                    {/*  */}
                    <div className="cs_row text_center my-2">
                        <p>{t("already_have_acct")}? <Link className="a-primary" style={{textTransform: "lowercase"}} to="/login"> {t("nav.login")} </Link></p>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SignUp