import { initializeApp } from "firebase/app"
import { getDatabase, ref, set, update, onValue, get, child, push } from "firebase/database"
import {
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
} from "firebase/auth";
import { getStorage, getDownloadURL, uploadBytes, uploadBytesResumable, ref as refForStorage } from "firebase/storage"

const firebaseConfig = {
    // apiKey: "AIzaSyBX-6kft7XCxmQx5-AGYVGn_LekO__UiGs",
    // authDomain: "smashcode-course-web.firebaseapp.com",
    // databaseURL: "https://smashcode-course-web-default-rtdb.firebaseio.com",
    // projectId: "smashcode-course-web",
    // storageBucket: "smashcode-course-web.appspot.com",
    // messagingSenderId: "566246732120",
    // appId: "1:566246732120:web:12ffbe357b5ed851f4b52d"

    // main 
    apiKey: "AIzaSyCT-aeu5CGKvvRz2VSUx_cfYmj3GXIEvas",
    authDomain: "smashcode-singular-trader.firebaseapp.com",
    databaseURL: "https://smashcode-singular-trader-default-rtdb.firebaseio.com",
    projectId: "smashcode-singular-trader",
    storageBucket: "smashcode-singular-trader.appspot.com",
    messagingSenderId: "365292011542",
    appId: "1:365292011542:web:50e50d59310499d990f537"
}

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
// Get a reference to the database service
const db = getDatabase(app);
const auth = getAuth();
const storage = getStorage(app);
// 
export {
    db,
    ref,
    set,
    update,
    onValue,
    getAuth,
    signInWithEmailAndPassword,
    signOut,
    createUserWithEmailAndPassword,
    auth,
    onAuthStateChanged,
    storage,
    get,
    child,
    uploadBytesResumable,
    uploadBytes,
    getDownloadURL,
    refForStorage,
    sendEmailVerification,
    sendPasswordResetEmail,
    push,
}