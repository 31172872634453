import i18next from 'i18next';
import React, { useEffect, useState } from 'react'
import cookie from "js-cookie";

// 
const LanguageSelector = () => {
    // 
    const languages = [
        { code: "en", name: "English", country_code: "gb" },
        { code: "pol", name: "polski", country_code: "pl" },
    ];

    const [activeLang, setActiveLang] = useState("en");

    // change language
    const changeLanguage = (code) => {
        if(code === cookie.get("i18next")) return
        i18next.changeLanguage(code);
        setActiveLang(code);
    };

    useEffect(() => {
        const currentLang = cookie.get("i18next") || "eng";
        setActiveLang(languages?.find((c) => c?.code === currentLang)?.code);
    }, []);

    return (
        <div className='cs_container w-100'>
            <div className="cs_row px-2 justify-content-end align-items-center">
                {languages?.map((v, i) => {
                    return (
                        <div
                            onClick={() => changeLanguage(v.code)}
                            role="button"
                            title={v?.name}
                            aria-label="change language"
                            key={i}
                            className={`lang-btn ${activeLang === v?.code ? "selected" : ""}`}
                        >
                            <span className={`flag-icon flag-icon-${v.country_code}`}></span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default LanguageSelector