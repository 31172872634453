import { useState, useEffect } from "react";
import {
  db,
  set,
  update,
  ref,
  child,
  push,
  storage,
  refForStorage,
  uploadBytesResumable,
  getDownloadURL,
} from "config/Firebase";
import { useData } from "context/DataContext";
import { useNotifyContext } from "context/Notify";
import { AiOutlineClose } from "react-icons/ai";
import { serverTimestamp } from "firebase/database";
import CourseTable from "./CourseTable";
import { runTransaction } from "firebase/database";

//
// generate random strings to attach with image name so every image has unique name
const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
function generateString(length) {
  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// ===========
let initial = {
  description: "",
  title: "",
  chapterNo: 1,
  lecture: "",
  publish: true,
  courseLang: "english"
};

//
const CourseManage = () => {
  // loader & alert
  const { user, data } = useData();
  const { set_notify } = useNotifyContext();
  // =============
  const [formData, setFormData] = useState(initial);
  const [percent, setPercent] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [tabData, setTabData] = useState([]);
  // const [thumbnail, setThumbnail] = useState("");
  //
  const [viewForm, setViewForm] = useState({ open: false, lang: "english" });
  const [UpdateMode, setUpdateMode] = useState({ enable: false });

  let getNewChapterNum = () => {
    return parseInt(tabData[tabData?.length - 1]?.chapterNo) + 1 || 1
    // return parseInt(data?.data[data?.data?.length - 1]?.chapterNo) + 1 || 1
  }

  useEffect(() => {
    if (!UpdateMode?.enable) {
      setFormData({
        ...formData,
        chapterNo: getNewChapterNum()
      });
    }
  }, [data, UpdateMode]);

  // 
  useEffect(() => {
    let sortedData = data?.data?.filter((v) => v?.courseLang === viewForm?.lang) || [];
    setTabData(sortedData)
  }, [data, user, viewForm]);


  // select file
  async function selectFiles(e) {
    let files = [];
    e.preventDefault();
    files = e.target.files;
    if (files.length > 0) {
      setFormData({
        ...formData,
        lecture: files[0],
      });
    }
  }

  // upload image on db
  function imageUploadToDB() {
    return new Promise((resolve, reject) => {
      if (formData?.lecture?.name) {
        var storageRef = refForStorage(
          storage,
          "course-videos/" + formData.lecture.name + generateString(10)
        );

        const uploadTask = uploadBytesResumable(storageRef, formData.lecture);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setPercent(progress);
            // switch (snapshot.state) {
            //     case 'paused':
            //         console.log('Upload is paused');
            //         break;
            //     case 'running':
            //         console.log('Upload is running');
            //         break;
            // }
          },
          (error) => {
            reject(error.msg);
            console.log(error);
            setIsLoading(false);
            setPercent(0);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
              return downloadURL;
            });
          }
        );
      } else {
        setPercent(26);
        resolve(formData.lecture || "");
        return formData.lecture || "";
      }
    });
  };

  //
  const sendFormData = () => {
    // =============================
    setIsLoading(true);
    document.querySelector(".dashboard_container").scrollTop = 0;
    if (UpdateMode?.enable) {
      imageUploadToDB()
        .then((link) => {
          update(ref(db, "course/" + UpdateMode?.id), {
            ...formData,
            lecture: link,
          })
            .then(() => {
              set_notify({
                open: true,
                msg: "Lesson Updated successfully.",
                type: "success",
              });
              setIsLoading(false);
              setFormData({ ...initial, chapterNo: getNewChapterNum() });
              setPercent(0);
              setViewForm({ open: false, lang: viewForm.lang });
              setUpdateMode({ enable: false });
            })
            .catch((err) => {
              console.log(err);
              set_notify({
                open: true,
                msg: "Lesson Update Failed.",
                type: "error",
              });
              setIsLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          set_notify({
            open: true,
            msg: "Video File Update failed",
            type: "error",
          });
          setIsLoading(false);
        });
    } else {
      const newPostKey = push(child(ref(db), "course")).key;
      imageUploadToDB()
        .then((link) => {
          set(ref(db, "course/" + newPostKey), {
            ...formData,
            lecture: link,
            timestamp: serverTimestamp(),
            id: newPostKey,
            viewsCompleted: 0,
            lang: formData?.courseLang || viewForm?.lang || "english"
          })
            .then(() => {
              set_notify({
                open: true,
                msg: "Lesson uploaded successfully.",
                type: "success",
              });
              setIsLoading(false);
              setFormData({ ...initial, chapterNo: getNewChapterNum() });
              setPercent(0);
              setViewForm({ open: false, lang: viewForm.lang });
            })
            .catch((err) => {
              console.log(err);
              set_notify({
                open: true,
                msg: "Lesson uploaded Failed.",
                type: "error",
              });
              setIsLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          set_notify({
            open: true,
            msg: "Video File uploading failed",
            type: "error",
          });
          setIsLoading(false);
        });
    }
    // ============================
  };
  //
  const uploadNewLesson = (e) => {
    e.preventDefault();
    if (formData?.lecture) {
      if (formData.chapterNo?.toString()?.length > 0) {
        let check = tabData?.filter(
          (v) => parseInt(v?.chapterNo) === parseInt(formData?.chapterNo)
        );
        if (check?.length > 0) {
          if (check[0]?.id === formData?.id) {
            sendFormData();
          } else {
            set_notify({
              open: true,
              msg: `Chapter number ${formData?.chapterNo} is already exist.`,
              type: "info",
            });
          }
        } else {
          sendFormData();
        }
      } else {
        set_notify({
          open: true,
          msg: "Please enter a lesson number.",
          type: "info",
        });
      }
    } else {
      set_notify({
        open: true,
        msg: "Please upload a lesson file and try again.",
        type: "info",
      });
    }
  };

  const handleWatched = (blogId, setActiveTopicData) => {
    const postRef = ref(db, `/course/${blogId}`);
    let uid = user?.data?.id;
    runTransaction(postRef, (post) => {
      if (post) {
        if (post.isCompleted && post.isCompleted[uid]) {
          post.viewsCompleted--;
          post.isCompleted[uid] = null;
        } else {
          post.viewsCompleted++;
          if (!post.isCompleted) {
            post.isCompleted = {};
          }
          post.isCompleted[uid] = true;
        }
      }
      setActiveTopicData({
        isSelected: true,
        ...post,
      });
      return post;
    });
  };

  return (
    <div className="page_wrapper">
      {viewForm?.open ? (
        <div className="cs_row w-100 justify-content-between align-items-center">
          <button
            className="cs_btn"
            disabled={isLoading}
            onClick={() => {
              setViewForm({ open: false, lang: viewForm.lang });
              setFormData({ ...initial, chapterNo: getNewChapterNum() });
              setUpdateMode({ enable: false });
            }}
          >
            Go Back
          </button>
        </div>
      ) : (
        <>
          <div className="cs_row w-100 justify-content-between align-items-center">
            <h3>All Lectures</h3>
            <button
              className="cs_btn"
              onClick={() => {
                setViewForm({ open: true, lang: viewForm.lang });
                setFormData({ ...initial, chapterNo: getNewChapterNum() });
                setUpdateMode({ enable: false });
              }}
            >
              Add New
            </button>
          </div>
          <div className="cs_row w-100 justify-content-center tabs-row align-items-center">
            <button
              className={`cs_btn ${viewForm?.lang === "english" ? "active-tab-btn" : ""}`}
              onClick={() => {
                setViewForm({ open: false, lang: "english" });
                setFormData({ ...initial, chapterNo: getNewChapterNum(), courseLang: "english" });
                setUpdateMode({ enable: false });
              }}
            >
              English
            </button>
            <button
              className={`cs_btn ${viewForm?.lang === "polish" ? "active-tab-btn" : ""}`}
              onClick={() => {
                setViewForm({ open: false, lang: "polish" });
                setFormData({ ...initial, chapterNo: getNewChapterNum(), courseLang: "polish" });
                setUpdateMode({ enable: false });
              }}
            >
              Polish
            </button>
          </div>
        </>
      )}
      {/* <img src={thumbnail} width="250px" height="250px" alt="" /> */}
      {viewForm?.open ? (
        <div className="cs_container">
          <br />
          <form
            className={`cs_form login_form ${isLoading ? "loading" : ""}`}
            onSubmit={uploadNewLesson}
          >
            {isLoading && (
              <div className="cs_progress_wrapper">
                <div
                  className="cs_progress"
                  style={{ width: percent + "%" }}
                ></div>
              </div>
            )}
            {/*  */}
            <div className="form_heading">
              <h3>Upload A New Lesson/Chapter</h3>
            </div>
            {/* upload video section */}
            <div className="w-100 cs_row align-items-center my-2">
              <input
                type="file"
                placeholder="Select"
                name="lecture"
                id="lecture"
                onChange={selectFiles}
                accept="video/*"
              />
              {formData?.lecture && (
                <button
                  onClick={() => {
                    setFormData({ ...formData, lecture: "" });
                  }}
                  className="cs_icon_btn sm"
                >
                  <AiOutlineClose />
                </button>
              )}
            </div>
            {UpdateMode &&
              formData?.lecture &&
              typeof formData?.lecture === "string" && (
                <>
                  {" "}
                  One File is selected: &nbsp;
                  <a
                    href={formData?.lecture}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    view
                  </a>
                </>
              )}
            <div className="w-100 my-2">
              <div className="w-100">
                <label htmlFor="title"> Chapter Name/Title:</label>
              </div>
              <input
                type="text"
                placeholder="Enter Title"
                name="title"
                id="title"
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                value={formData.title}
              />
            </div>
            <div className="w-100 my-2">
              <div className="w-100">
                <label htmlFor="chapterNo"> Chapter Number:</label>
              </div>
              <input
                type="number"
                placeholder="Enter Number"
                name="chapterNo"
                id="chapterNo"
                onChange={(e) =>
                  setFormData({ ...formData, chapterNo: e.target.value })
                }
                onWheel={(e) => e.currentTarget.blur()}
                value={formData.chapterNo}
              />
            </div>
            <div className="w-100 my-2">
              <div className="w-100">
                <label htmlFor="publish"> Publish:</label>
              </div>
              <select
                name="publish"
                id="publish"
                onChange={(e) =>
                  setFormData({ ...formData, publish: e.target.value })
                }
                value={formData.publish}
              >
                <option name="publish" value={true}>
                  Yes
                </option>
                <option name="publish" value={false}>
                  No
                </option>
              </select>
            </div>
            <div className="w-100 my-2">
              <div className="w-100">
                <label htmlFor="courseLang"> Course Medium:</label>
              </div>
              <select
                name="courseLang"
                id="courseLang"
                onChange={(e) => {
                  setFormData({ ...formData, courseLang: e.target.value });
                  setViewForm({ open: true, lang: e.target.value })
                }}
                value={formData.courseLang}
              >
                <option name="courseLang" value="english">
                  English
                </option>
                <option name="courseLang" value="polish">
                  Polish
                </option>
              </select>
            </div>
            {/*  */}
            <div className="w-100 my-2">
              <div className="w-100">
                <label htmlFor="description">Lesson Short Brief:</label>
              </div>
              <textarea
                placeholder="Type here..."
                name="description"
                id="description"
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                value={formData.description}
              />
            </div>
            <div className="cs_row py-2 justify-content-center align-items-center">
              <button type="submit" className="cs_btn">
                {UpdateMode ? "Save Changes" : "Upload"}
              </button>
            </div>
          </form>
        </div>
      ) : (
        <CourseTable
          setViewForm={() => setViewForm({ open: true, lang: viewForm.lang })}
          setUpdateMode={setUpdateMode}
          setFormData={setFormData}
          isAdmin={true}
          handleWatched={handleWatched}
          formData={formData}
          data={tabData}
        />
      )}
    </div>
  );
};

export default CourseManage;
