import { useState,useEffect, useRef } from 'react'
import { useNotifyContext } from 'context/Notify'
import { db, update,set, ref, storage, refForStorage, uploadBytes, getDownloadURL, auth } from "config/Firebase"
import { useData } from 'context/DataContext'
import { AiOutlineCloseCircle } from "react-icons/ai";
import defaultimg from "assets/images/logo.png";
import Loader from "utils/Loader"
import { useTranslation } from 'react-i18next';

// generate random strings to attach with image name so every image has unique name
const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
function generateString(length) {
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

let initials = {
    firstName: '', lastName: '', email: "", photoURL: "", id: "", about: "",
};

const ProfileManage = () => {
    const { t } = useTranslation();
    const { set_notify, setIsLoading, isLoading } = useNotifyContext();
    const { user, setUser } = useData();
    const [userDetails, setUserDetails] = useState(user.data || initials);
    const imgRef = useRef(null);

    useEffect(()=>{
        setUserDetails(user.data || initials)
    },[user]);

    // select file
    function selectImg(e) {
        let files = [];
        let reader;
        e.preventDefault();
        files = e.target.files;

        if (files.length > 0) {
            reader = new FileReader();
            reader.onload = function () {
                imgRef.current.src = reader.result;
            };
            reader.readAsDataURL(files[0]);
            setUserDetails({
                ...userDetails,
                photoURL: files[0]
            });
        }

    }

    // handle form details on inputs change
    const handleFormDetails = (e) => {
        let { value, name } = e.target;
        setUserDetails({ ...userDetails, [name]: value });
    };

    // reset form details
    const resetForm = () => {
        setUserDetails(user.data || {});
    };


    // upload image on db
    function imageUploadToDB() {
        return new Promise((resolve, reject) => {
            if (userDetails?.photoURL?.name) {
                var storageRef = refForStorage(storage, "user-images/" + userDetails.photoURL.name + generateString(10));
                uploadBytes(storageRef, userDetails.photoURL).then((response) => {
                    getDownloadURL(response.ref).then((downloadURL) => {
                        resolve(downloadURL);
                        return downloadURL
                    });
                }).catch((error) => {
                    reject(error.msg)
                    setIsLoading(false)
                });
            } else {
                resolve(userDetails.photoURL || "")
                return userDetails.photoURL || ""
            }
        });
    }

    // Save details
    const saveProfile = (e) => {
        e.preventDefault();
        let id = user?.data?.id || auth?.currentUser?.uid;
        if (id) {
            setIsLoading(true);
            imageUploadToDB().then((profileImg) => {
                setIsLoading(false);
                let pImg = profileImg;
                // let  setToDb = id ?  update : set;
                update(ref(db, 'users/' + id), {
                    ...userDetails,
                    photoURL: pImg,
                }).then(() => {
                    set_notify({ open: true, msg: "Changes saved successfully", type: "success" })
                    setIsLoading(false);
                    setUser({
                        ...user, data: {
                            ...userDetails,
                            photoURL: pImg,
                        }
                    })
                }).catch((err) => {
                    console.log(err)
                    set_notify({ open: true, msg: "some thing went wrong! plz make sure your internet connection is connected.", type: "error" })
                    setIsLoading(false);
                });
            }).catch((err) => {
                console.log(err)
                set_notify({ open: true, msg: "Profile image uploading failed", type: "error" })
                setIsLoading(false);
            });
        } else {
            set_notify({ open: true, msg: "User id not found! plz make sure your internet connection is connected.", type: "error" })
        }
    };

    function removeImg() {
        imgRef.current.src = '';
        setUserDetails({
            ...userDetails,
            photoURL: ''
        });
    }

    return (
        <div className="container">
            <div className="row">
                <div style={{ pointerEvents: isLoading && "none", opacity: isLoading && ".7" }} className="profile_form">
                    <div className="cs_row justify-content-between my-2">
                        <h3 className="">{t("edit_profile")}</h3>
                    </div>
                    <form onSubmit={saveProfile} className="w-100 ">
                        <div className="cs_row flex-wrap">
                            <div className='w-100 text_center'>
                                <div className="cs_row my-2 justify-content-center pb-3">
                                    <div className="selectImg-wrapper">
                                        <input onChange={selectImg} id="imageUpload" type="file" accept="image/*" />
                                        {userDetails.photoURL ?
                                            <>
                                                <AiOutlineCloseCircle
                                                    onClick={removeImg}
                                                    className="remove-img-btn"
                                                />
                                                <label htmlFor="imageUpload">
                                                    <img ref={imgRef} src={userDetails.photoURL || defaultimg} alt="" />
                                                </label>
                                            </>
                                            :
                                            <>
                                                <label htmlFor="imageUpload" className="img-picker">
                                                    <svg
                                                        width="36px"
                                                        height="36px"
                                                        viewBox="0 0 1024 1024"
                                                        data-aut-id="icon"
                                                        fill="var(--primary)"
                                                    >
                                                        <path d="M861.099 667.008v78.080h77.568v77.653h-77.568v77.141h-77.568v-77.184h-77.611v-77.611h77.611v-78.080h77.568zM617.515 124.16l38.784 116.437h165.973l38.827 38.827v271.659l-38.827 38.357-38.741-38.4v-232.832h-183.125l-38.784-116.48h-176.853l-38.784 116.48h-183.083v426.923h426.667l38.784 38.357-38.784 39.253h-465.493l-38.741-38.869v-504.491l38.784-38.827h165.973l38.827-116.437h288.597zM473.216 318.208c106.837 0 193.92 86.955 193.92 194.048 0 106.923-87.040 194.091-193.92 194.091s-193.963-87.168-193.963-194.091c0-107.093 87.083-194.048 193.963-194.048zM473.216 395.861c-64.213 0-116.352 52.181-116.352 116.395 0 64.256 52.139 116.437 116.352 116.437 64.171 0 116.352-52.181 116.352-116.437 0-64.213-52.181-116.437-116.352-116.437z"></path>
                                                    </svg>
                                                </label>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col_50 my-2">
                                <input
                                    onChange={handleFormDetails}
                                    value={userDetails.firstName}
                                    name="firstName"
                                    type="text"
                                    placeholder={t("f_name_placeholder")}
                                />
                            </div>
                            <div className="col_50 form_input_right_col my-2">
                                <input
                                    onChange={handleFormDetails}
                                    value={userDetails.lastName}
                                    name="lastName"
                                    type="text"
                                    placeholder={t("l_name_placeholder")} />
                            </div>
                            <div className="col_100  my-2">
                                <input
                                    value={userDetails.email}
                                    name="email"
                                    readOnly
                                    type="email"
                                    className="only_read"
                                    placeholder={t("email_placeholder")} />
                            </div>
                            <div className="col_100 my-2">
                                <textarea
                                    rows={7}
                                    onChange={handleFormDetails}
                                    value={userDetails?.about}
                                    name="about"
                                    type="text"
                                    placeholder="About" />
                            </div>
                            <div className="w-100 my-2"><hr /></div>
                            <div className="w-100 cs_row flex-wrap justify-content-between">
                                <button type="button" className="cs_btn" onClick={resetForm}>
                                    Reset
                                </button>
                                {isLoading ?
                                    <button className="cs_btn" disabled>
                                        <Loader type="spinner" />
                                        &nbsp; Loading...
                                    </button>
                                    :
                                    userDetails.email ?
                                        <button className="cs_btn" type="submit">
                                            Save changes
                                        </button>
                                        :
                                        <button className="cs_btn" disabled>
                                            Save changes
                                        </button>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}



export default ProfileManage;