import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
//
// Data Context
import DataContextProvider from "context/DataContext";
import { BrowserRouter } from "react-router-dom";
import NotifyContextProvider from "context/Notify";
//
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import Loader from "utils/Loader";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "pol"],
    fallbackLng: "en",
    detection: {
      order: ["path", "cookie", "htmlTag", "localStorage", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

ReactDOM.render(
  <BrowserRouter>
    <NotifyContextProvider>
      <DataContextProvider>
        <Suspense
          fallback={
            <main className="page_wrapper">
              <div className="cs_container text_center">
                <Loader type="imgloader" />
              </div>
            </main>
          }
        >
          <App />
        </Suspense>
      </DataContextProvider>
    </NotifyContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
