import { useState } from 'react'
import { useData } from 'context/DataContext'
import Loader from "utils/Loader";
import Login from "components/login";
import webLogo from "assets/images/logo.png";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { AiOutlineMenu, AiOutlineClose, AiOutlineUserSwitch } from 'react-icons/ai';
import { MdOutlineDashboard, MdAccountBox } from 'react-icons/md';
import { RiLogoutCircleRLine } from 'react-icons/ri';
import { FaHome } from "react-icons/fa";
import Modal from "utils/Modal";
import { signOut, auth } from "config/Firebase";
// 
import Profile from "components/Admin/ProfileManage"
import CourseManage from "components/Admin/CourseManage/CourseManage"
import CoursePanel from "components/UserPanel/CoursePanel"
import UsersManage from "components/Admin/usersManage"
import { useTranslation } from 'react-i18next';

// dashboard
const Dashboard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { tabName } = useParams();
    // 
    const [sideBarOpen, setSideBar] = useState(false);
    // 
    const [logoutModal, setLogoutModal] = useState(false);
    // data context/DataContext
    const { data, user } = useData();

    // list
    const tabList = [
        { name: "Course", route: "course", icon: <MdOutlineDashboard />, component: user?.data?.isAdmin ? <CourseManage /> : <CoursePanel /> },
        { name: "Users", route: "users", isSecure: true, icon: <AiOutlineUserSwitch />, component: <UsersManage  /> },
        { name: "Profile", route: "profile", isSecure: false, icon: <MdAccountBox />, component: <Profile /> },
    // ]:[]
    ];
    // const tabList = auth?.currentUser?.emailVerified || user?.data?.isAdmin ? [
    //     { name: "Course", route: "course", icon: <MdOutlineDashboard />, component: user?.data?.isAdmin ? <CourseManage /> : <CoursePanel /> },
    //     { name: "Users", route: "users", isSecure: true, icon: <AiOutlineUserSwitch />, component: <UsersManage /> },
    //     { name: "Profile", route: "profile", isSecure: false, icon: <MdAccountBox />, component: <Profile /> },
    // ]: []
    // ;

    const logout = () => {
        signOut(auth).then(() => {
            setLogoutModal(false);
            navigate("/", { replace: true });
        }).catch((err) => {
            setLogoutModal(false);
            console.log(err)
            alert("Something went wrong!")
        })
    }

    if (user?.isAuth) {
        return (
            <>
                {sideBarOpen && <div onClick={() => setSideBar(false)} className="sidebar_backdrop"></div>}
                <div className="dashboardContainer">
                    {/* left sidebar */}
                    <aside className={`dashboard_sideBar ${sideBarOpen ? ' dashboardSideBar_opened' : ""}`}>
                        <Link to="/" className="dashboard_logo">
                            <img src={webLogo} alt="" />
                        </Link>
                        <nav className={`dashboard_nav  `}>
                            {tabList.map((tabV, i) => (
                                tabV.isSecure ? (
                                    user?.data?.isAdmin ?
                                        <Link key={i}
                                            onClick={() => setSideBar(false)}
                                            className={`${tabName === tabV.route ? "activeLinkDashTab" : ""} dashboardLinks`}
                                            to={`/dashboard/${tabV.route}`}>
                                            {tabV.icon}
                                        </Link>
                                        : null
                                )
                                    :
                                    <Link key={i}
                                        onClick={() => setSideBar(false)}
                                        className={`${tabName === tabV.route ? "activeLinkDashTab" : ""} dashboardLinks`}
                                        to={`/dashboard/${tabV.route}`}>
                                        {tabV.icon}

                                    </Link>
                            ))}
                            <hr />
                            <Link className="dashboardLinks" to="/">
                                <FaHome />
                            </Link>
                        </nav>
                    </aside>
                    <main className="dashboard_right_col_main">
                        {/* dashboard Header */}
                        <header className="dashboard_header">
                            <div className="dashboard_header_container cs_row justify-content-start align-items-center">
                                <div className="dashboardMenuOpen" onClick={() => setSideBar(!sideBarOpen)}>
                                    {sideBarOpen ? <AiOutlineClose />
                                        : <AiOutlineMenu />}
                                </div>
                                <h3 className="dashboard_title">{user?.data?.isAdmin ? "Admin ":""}{t("dashboard")}</h3>
                                <div className="dashboardToolbar">
                                    <span onClick={() => setLogoutModal(true)} className="text_links_ds">{t("logout")}</span>
                                    <div onClick={() => setLogoutModal(true)} className="toolbarIconBox">
                                        <RiLogoutCircleRLine className="icon_" />
                                    </div>
                                    <div className="toolbarIconBox adminAvatarBox">
                                        <img className="adminAvatar" src={user?.data?.photoURL || webLogo} alt={user?.data?.firstName?.slice(0, 1)} />
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="dashboard_container">
                            {/* {auth?.currentUser?.emailVerified || user?.data?.isAdmin ? */}
                            {
                            // user?.data?.isAdmin ?
                            tabList.map((com, i) => (
                                <div key={i}>
                                    {com.route === tabName ? (
                                        com?.isSecure ? (
                                            user?.data?.isAdmin ? (
                                                com?.component
                                            )
                                                : null
                                        )
                                            : com?.component
                                    )
                                        : null
                                    }
                                </div>
                            ))
                            // : <EmailIsNotVerified/>
                        }
                       
                        </div>
                    </main>
                </div>
                <Modal
                    open={logoutModal}
                    setClose={setLogoutModal}
                >
                    <div className="cs_modal ask_modal">
                        {/* <div className="cs_modal_header cs_row justify-content-between align-items-center">
                            <img src={webLogo} alt="" />
                        </div> */}
                        <div className="cs_modal_body">
                            <h2>{t("alert")}!</h2>
                            <p>
                                {t("are_you_want_to_logout")}?
                            </p>
                        </div>
                        <div className="cs_modal_footer cs_row justify-content-between align-items-center">
                            <button className="cs_btn" onClick={logout}>{t("logout")}</button>
                            <button className="cs_btn" onClick={() => setLogoutModal(false)}>{t("close")}</button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
    else if (user.isLoading) {
        return (
            <main className="page_wrapper">
                <div className="cs_container text_center">
                    <Loader type="imgloader" />
                </div>
            </main>
        )
    } else {
        return <Login />
    }
};

// 

// const EmailIsNotVerified = () => {
//     const { send_Verification_Email } = useData();
//     return(
//         <div className="">
//             <div className="cs_container  text_center">
//                 <br /><br /><br />
//                 <button onClick={()=> send_Verification_Email()} className="cs_btn my-2 mx_auto">
//                     Send Verification Email
//                 </button>
//             </div>
//         </div>
//     )
// }


export default Dashboard;