import { createContext, useEffect, useState, useContext } from 'react';
import { db, ref, onValue, auth, onAuthStateChanged, child, get, sendEmailVerification } from 'config/Firebase'
import { useNotifyContext } from './Notify'
import { equalTo, orderByChild, query } from 'firebase/database';

const DataContext = createContext();

// sort by any value
const sort_by = (field, reverse, primer) => {

    const key = primer ?
        function (x) {
            return primer(x[field])
        } :
        function (x) {
            return x[field]
        };

    reverse = !reverse ? 1 : -1;

    return function (a, b) {
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
}



const DataContextProvider = ({ children }) => {
    // 
    const { set_notify, setIsLoading } = useNotifyContext();
    // 
    const [user, setUser] = useState({ isAuth: false, isLoading: true, data: {} });
    const [users, setUsers] = useState({ isLoading: true, data: [] });
    const [data, setData] = useState({
        loaded: false,
        data: []
    });

    // get course data
    const getCourseData = (userRec) => {
        const courseStoreRef = ref(db, '/course');
        if (userRec?.isAdmin) {
            onValue(courseStoreRef, (snapshot) => {
                const dbData = Object.values(snapshot.val() || {})?.map(value => value);
                const withSort = dbData.sort(sort_by('chapterNo', false, parseInt))
                setData({ data: withSort, loaded: true });
            });
        } else {
            if (userRec?.paymentCompleted) {
                let typeC = userRec?.courseLang === "polish" ? "polish" : "english";
                onValue(query(courseStoreRef, orderByChild("courseLang"), equalTo(typeC)), (snapshot) => {
                    const dbData = Object.values(snapshot.val() || {})?.map(value => value);
                    const withSort = dbData.sort(sort_by('chapterNo', false, parseInt))
                    setData({ data: withSort, loaded: true });
                });
            } else {
                setData({ data: [], loaded: true });
            }
        }
    }
    // 
    useEffect(() => {
        
        const getData = () => {

            // 
            // get users records
            onValue(ref(db, '/users'), (snapshot) => {
                if (snapshot.exists()) {
                    const dbData = Object.values(snapshot.val()).map(value => value);
                    setUsers({ data: dbData, isLoading: false });
                }
            });

            const retry = (dbuser) => {
                get(child(ref(db), `users/${dbuser?.uid}`)).then((snapshot) => {
                    if (snapshot.exists()) {
                        getCourseData(snapshot.val())
                        setUser({ isAuth: true, isLoading: false, data: snapshot.val() })
                    }
                })
            }

            //========= user ===============
            onAuthStateChanged(auth, (user_) => {
                let userD = user_ || auth?.currentUser
                if (userD) {
                    const dbRef = ref(db);
                    get(child(dbRef, `users/${userD?.uid}`)).then((snapshot) => {
                        if (snapshot.exists()) {
                            getCourseData(snapshot.val())
                            setUser({ isAuth: true, isLoading: false, data: snapshot.val() })
                        } else {
                            setUser({ isAuth: true, isLoading: false, data: { ...userD } });
                            setTimeout(() => {
                                retry(userD)
                            }, 500)
                        }
                    }).catch((error) => {
                        console.error(error);
                        setUser({ isAuth: true, isLoading: false, data: { ...userD } })
                        setTimeout(() => {
                            retry(userD)
                        }, 500)
                    });
                } else {
                    setUser({ isAuth: false, isLoading: false, data: {} })
                }
            });
        };

        return getData()
    }, []);

    // send verification email
    const send_Verification_Email = () => {
        setIsLoading(true);
        return new Promise((resolve, reject) => {
            sendEmailVerification(auth.currentUser).then(() => {
                set_notify({ open: true, msg: `Verification email has been sent to ${user?.data?.email || "your email."}`, type: "success" });
                setIsLoading(false);
                return resolve("Email has been sent")
            }).catch((error) => {
                setIsLoading(false);
                set_notify({ open: true, msg: `Error occurred while sending Verification email to ${user?.data?.email || "your email."}`, type: "error" });
                console.log(error)
                return reject("Failed to sending verification Email")
            })
        })
    };

    return (
        <DataContext.Provider value={{ data, user, setUser, users, send_Verification_Email, getCourseData }}>
            {children}
        </DataContext.Provider>
    )
};

export const useData = () => useContext(DataContext);

export default DataContextProvider;

