import Router from "config/routes";
import { ArrowUpIcon } from 'utils/CsIcon'
// css
import "assets/css/style.css";
import "flag-icon-css/css/flag-icons.min.css";
import "./pages/Dashboard/dashboard.css"


function App() {
  console.clear();
  console.warn = () => {};
  return (
    <>
        <Router/>
        <GoToTop />
    </>
  );
}


// Go top top button
const GoToTop = () => {
  const scrollUpW = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }
  return (
    <div onClick={scrollUpW} className="go-to-top" id="scrollToTop">
      <ArrowUpIcon />
    </div>
  )
};


export default App;
